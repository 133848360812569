import React from "react"
import { Link } from "gatsby"

const Logo = props => (
  <div>
    <Link to="/">
      <img className={"site-logo"} src={props.imageUrl} alt={props.alt} />
    </Link>
  </div>
)

export default Logo
