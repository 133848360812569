/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { RiHeart2Line } from "react-icons/ri"
import { useStaticQuery, graphql } from 'gatsby'

const pageQuery = graphql`
  query FooterQuery {
    site {
      siteMetadata {
        footnote: description
      }
    }
  }
`
const Footer = () => {
  const { site } = useStaticQuery(pageQuery);
  return (
    <footer
      className="site-footer"
      sx={{
        bg: "siteColor",
      }}
    >
      <div className="container">
        <p>
          {site.siteMetadata.footnote}{" "}
          <span className="icon -love">
            <RiHeart2Line />
          </span>{" "}
          by <Link to="//mente.al">mente.al</Link>
        </p>
      </div>
    </footer>
  )
}
export default Footer
